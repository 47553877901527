import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customers/list`}
          component={lazy(() => import(`./pages/customers/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customers/view`}
          component={lazy(() => import(`./pages/customers/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customers/edit`}
          component={lazy(() => import(`./pages/customers/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/customers/add`}
          component={lazy(() => import(`./pages/customers/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mailing/list`}
          component={lazy(() => import(`./pages/mailing/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mailing/view`}
          component={lazy(() => import(`./pages/mailing/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mailing/edit`}
          component={lazy(() => import(`./pages/mailing/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/mailing/add`}
          component={lazy(() => import(`./pages/mailing/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reward-points/list`}
          component={lazy(() => import(`./pages/rewardPoints/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reward-points/add`}
          component={lazy(() => import(`./pages/rewardPoints/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reward-points/view`}
          component={lazy(() => import(`./pages/rewardPoints/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/reward-points/edit`}
          component={lazy(() => import(`./pages/rewardPoints/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotions/list`}
          component={lazy(() => import(`./pages/promotions/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotions/view`}
          component={lazy(() => import(`./pages/promotions/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotions/edit`}
          component={lazy(() => import(`./pages/promotions/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promotions/add`}
          component={lazy(() => import(`./pages/promotions/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/campaigns/list`}
          component={lazy(() => import(`./pages/campaigns/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/campaigns/view`}
          component={lazy(() => import(`./pages/campaigns/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/campaigns/edit`}
          component={lazy(() => import(`./pages/campaigns/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/campaigns/add`}
          component={lazy(() => import(`./pages/campaigns/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedback/list`}
          component={lazy(() => import(`./pages/feedback/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-stores/list`}
          component={lazy(() => import(`./pages/mystores/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-stores/add`}
          component={lazy(() => import(`./pages/mystores/edit`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-stores/edit`}
          component={lazy(() => import(`./pages/mystores/edit`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-stores/view`}
          component={lazy(() => import(`./pages/mystores/edit`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-team/list`}
          component={lazy(() => import(`./pages/myteam/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-team/add`}
          component={lazy(() => import(`./pages/myteam/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-team/view`}
          component={lazy(() => import(`./pages/myteam/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/my-team/edit`}
          component={lazy(() => import(`./pages/myteam/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support-requests/list`}
          component={lazy(() => import(`./pages/support-requests/list`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support-requests/add`}
          component={lazy(() => import(`./pages/support-requests/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support-requests/view`}
          component={lazy(() => import(`./pages/support-requests/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/support-requests/edit`}
          component={lazy(() => import(`./pages/support-requests/add`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/billings`}
          component={lazy(() => import(`./pages/billings`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./pages/settings`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./pages/setting/EditProfile`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/help`}
          component={lazy(() => import(`./pages/faq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
