import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { AUTH_TOKEN, SIGNIN, SIGNOUT, USER_INFO } from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
} from "../actions/Auth";

import SigninService from "services/AuthService";

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password, rememberChecked } = payload;
    try {
      const res = yield call(SigninService.signIn, email, password);
      if (res.message) {
        yield put(showAuthMessage(res.message));
      } else {
        const { first_name, last_name } = res.data.user.personId;
        const name = first_name + " " + last_name;
        const { user_id } = res.data.user;
        const { account_id, bus_name } = res.data.user.accountId;
        const userInfo = { name, user_id, account_id, bus_name };
        if (rememberChecked) {
          localStorage.setItem(AUTH_TOKEN, res.token);
          localStorage.setItem(USER_INFO, JSON.stringify(userInfo));
        }
        yield put(authenticated(res.token, userInfo));
      }
    } catch (err) {
      console.log(err);
      yield put(showAuthMessage(err.message));
    }
  });
}

// export default function* rootSaga() {
//   yield all([fork(signInWithEmail)]);
// }

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      // const signOutUser = yield call();
      // if (signOutUser === undefined) {
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
      // } else {
      //   yield put(showAuthMessage(signOutUser.message));
      // }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithEmail), fork(signOut)]);
}
