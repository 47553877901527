const { API_BASE_URL } = require("constants/ApiConstant");

export default class AuthService {
  static signIn(email, password) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email, password }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static forgotPassword(email) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static verifyOTP(email, otp) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email, otp }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static resetPassword(email, otp, newPassword) {
    return new Promise((resolve, reject) => {
      fetch(`${API_BASE_URL}/api/v1/auth/reset-password-web`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email, otp, newPassword }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status) {
            resolve(res);
          } else {
            reject({ message: res.message });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
